import { motion } from 'framer-motion'
import styled from 'styled-components'
import { Flex } from 'styles/globalStyle'

export const WavesContainer = styled.div``

export const WavesInner = styled.div`
  width: 300px;
  height: 300px;
  position: relative;
  margin: 0 auto;
`

export const Waves = styled(motion.div)`
  box-shadow: inset -10px -10px 10px 0 #fff,
    inset 10px 10px 10px 10px rgb(136 165 191 / 10%);
  border-radius: 50%;
  transform: scale(0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`

export const SpeakerBg = styled.div`
  background: ${({ theme }) => theme.colors.primary[500]};
  width: 175px;
  height: 175px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SpeakerIcon = styled.div`
  background: ${({ theme }) => theme.colors.gray[50]};
  width: 90px;
  height: 90px;
  border-radius: 50%;
  box-shadow: 0 0 6px 2px rgb(255 255 255 / 57%), inset 2px 2px 7px 3px #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
`

export const SpeakerButton = styled.div`
  width: 200px;
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  background-image: linear-gradient(135deg, #fff, #fff);
  box-shadow: 5px 5px 11px 3px rgb(0 0 0 / 10%);
  transform: translate(-50%, -50%);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    ${SpeakerIcon} {
      box-shadow: 0 0 6px 2px rgb(255 255 255 / 85%), inset 2px 2px 7px 3px #fff;
    }
  }
`

export const WavesButtons = styled(Flex)`
  position: relative;
  z-index: 5;
  justify-content: center;
`
export const WavesSound = styled.div`
  display: none;
  width: 0;
  height: 0;
`
