import React, { FC } from 'react'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import {
  Box,
  Flex,
  HeroContainer,
  HeroHome,
  HeroHomeArrow,
  HeroImage,
  HeroSpeakerText,
} from 'styles/globalStyle'
import { BasicPageTypes } from 'types/basicPageTypes'
import Layout from 'components/Layout'
import { Title, Text, Icons } from 'components/theme'
import SliceSection from 'components/SliceSection'
import SoundWaves from 'components/SoundWaves'
import SEO from 'components/SEO'

type HomeParams = {
  data: BasicPageTypes
}

const IndexPage: FC<HomeParams> = ({ data }) => {
  const { title_meta, description_meta, hero_title, body } =
    data.prismicBasicPage.data

  return (
    <Layout>
      <SEO title={title_meta.text} description={description_meta.text} />
      <HeroHome>
        <HeroImage>
          <StaticImage
            className="desktop"
            src="../assets/images/homeHero.jpg"
            alt="Home hero image"
          />
          <StaticImage
            className="mobile"
            src="../assets/images/homeHeroMobile.jpg"
            alt="Home hero mobileimage"
          />
        </HeroImage>
        <HeroContainer home>
          <div>
            {/* <Flex mb="2.5rem">
              <StaticImage
                src="../assets/images/product1.png"
                alt="Product 1 hero"
                className="hero-product"
              />
              <StaticImage
                src="../assets/images/product2.png"
                alt="Product 2 hero"
                className="hero-product"
              />
              <StaticImage
                src="../assets/images/product3.png"
                alt="Product 3 hero"
                className="hero-product"
              />
            </Flex> */}
            <Text fontWeight="600" color="white" mb="0.75rem">
              Bienvenue chez Audiplus
            </Text>
            <Title as="h1" size="lg" mb="1.5rem" color="white">
              {hero_title.text}
            </Title>
          </div>
          <Flex flexDirection="column" alignItems="center" position="relative">
            <HeroSpeakerText
              animate={{ opacity: [0, 1, 0] }}
              transition={{ duration: 3, repeat: Infinity }}
            >
              <Box
                position="relative"
                bottom="-1rem"
                backgroundColor="error.600"
                padding="0.5rem 2rem"
                borderRadius="70px"
                width="auto"
              >
                <Text size="xl" color="white" fontWeight="600">
                  Par ici, c’est nouveau !
                </Text>
              </Box>
              <Box
                backgroundColor="primary.25"
                padding="1.5rem 2rem 1rem"
                borderRadius="1rem"
              >
                <Text
                  size="xxl"
                  fontWeight="600"
                  color="primary.700"
                  textAlign="center"
                >
                  Testez l’effet que procure des <br /> appareils auditifs en 2
                  clics
                </Text>
              </Box>
              <HeroHomeArrow>
                <Icons name="arrow-hero" />
              </HeroHomeArrow>
            </HeroSpeakerText>
            <SoundWaves />
            <Text
              mt="1rem"
              mb="2rem"
              fontWeight="500"
              color={['white', '', '', 'primary.600']}
            >
              Appuyez sur un bouton pour entendre la différence
            </Text>
            <StaticImage
              src="../assets/images/homeProduct.png"
              alt="Home Products"
              className="hero-product"
              placeholder="blurred"
            />
          </Flex>
        </HeroContainer>
      </HeroHome>
      {body.map((item, idx) => (
        <SliceSection key={idx} data={item} />
      ))}
    </Layout>
  )
}

export const query = graphql`
  query {
    prismicBasicPage(uid: { eq: "homepage" }) {
      data {
        ...PrismicBasicPageDataTypeFragment
      }
    }
  }
`

export default IndexPage
