import React, { FC, useRef, useState } from 'react'
import { Icons, Button, Text } from 'components/theme'
import {
  WavesContainer,
  WavesInner,
  SpeakerButton,
  SpeakerBg,
  SpeakerIcon,
  Waves,
  WavesButtons,
  WavesSound,
} from './styled'
import { TooltipLeft, TooltipRight } from 'styles/globalStyle'
import { AnimatePresence, motion } from 'framer-motion'

const SoundWaves: FC = () => {
  const [helperClick, setClick] = useState<
    'speaker' | 'with' | 'without' | null
  >('speaker')
  const [currentSound, setSound] = useState('without')
  const [isPaused, setPaused] = useState(false)
  const soundElWithout = useRef<HTMLMediaElement | null>(null)
  const soundElWith = useRef<HTMLMediaElement | null>(null)
  const clickButton = () => {
    if (helperClick) {
      setClick('without')
    } else {
      setClick(null)
    }

    if (soundElWith.current && soundElWithout.current) {
      if (currentSound === 'without') {
        if (soundElWithout.current.paused) {
          soundElWithout.current.play()
          setPaused(false)
        } else {
          soundElWithout.current.pause()
          setPaused(true)
        }
      } else {
        if (soundElWith.current.paused) {
          soundElWith.current.play()
          setPaused(false)
        } else {
          soundElWith.current.pause()
          setPaused(true)
        }
      }
    }
  }
  const switchAudio = (sound: string) => {
    setSound(sound)

    if (soundElWithout.current && soundElWith.current) {
      if (sound !== currentSound) {
        if (currentSound === 'without') {
          const currentTime = soundElWithout.current.currentTime
          soundElWithout.current.pause()
          soundElWith.current.currentTime = currentTime
          soundElWith.current.play()
        } else {
          const currentTime = soundElWith.current.currentTime
          soundElWith.current.pause()
          soundElWithout.current.currentTime = currentTime
          soundElWithout.current.play()
        }
        setPaused(false)
      }
    }

    if (helperClick === 'without') {
      setClick('with')
    } else {
      setClick(null)
    }
  }

  return (
    <WavesContainer>
      <WavesSound>
        <audio controls src="/without-device.m4a" ref={soundElWithout}>
          Your browser does not support the
          <code>audio</code> element.
        </audio>
        <audio controls src="/with-device.m4a" ref={soundElWith}>
          Your browser does not support the
          <code>audio</code> element.
        </audio>
      </WavesSound>
      <WavesInner>
        <SpeakerButton onClick={clickButton}>
          <SpeakerBg>
            <SpeakerIcon>
              <Icons
                name={isPaused ? 'volume-x' : 'volume-full'}
                width="45px"
                height="45px"
              />
            </SpeakerIcon>
          </SpeakerBg>
        </SpeakerButton>
        {[...Array(10).keys()].map((item) => (
          <Waves
            key={item}
            animate={{ scale: [0, 1.7], opacity: 0 }}
            transition={{
              duration: 10,
              repeat: Infinity,
              ease: 'easeInOut',
              delay: item * 1,
            }}
          />
        ))}
        <AnimatePresence initial={false} exitBeforeEnter>
          {helperClick === 'speaker' && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.2 }}
              exit={{ opacity: 0 }}
            >
              <TooltipLeft>
                <Text color="white">Cliquez ici</Text>
              </TooltipLeft>
            </motion.div>
          )}
        </AnimatePresence>
      </WavesInner>
      <WavesButtons>
        <AnimatePresence initial={false} exitBeforeEnter>
          {helperClick === 'with' && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.2 }}
              exit={{ opacity: 0 }}
            >
              <TooltipRight>
                <Text color="white">Cliquez ici</Text>
              </TooltipRight>
            </motion.div>
          )}
        </AnimatePresence>
        <Button
          type={currentSound === 'without' ? 'gradient' : 'light'}
          size="xl"
          mr="1rem"
          onClick={() => switchAudio('without')}
        >
          Sans appareils
        </Button>
        <Button
          type={currentSound === 'with' ? 'gradient' : 'light'}
          size="xl"
          onClick={() => switchAudio('with')}
        >
          Avec appareils
        </Button>
        <AnimatePresence initial={false} exitBeforeEnter>
          {helperClick === 'without' && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.2 }}
              exit={{ opacity: 0 }}
            >
              <TooltipLeft right={['-20%', '-35%']}>
                <Text color="white">Cliquez ici</Text>
              </TooltipLeft>
            </motion.div>
          )}
        </AnimatePresence>
      </WavesButtons>
    </WavesContainer>
  )
}
export default SoundWaves
